import React from "react"
import { StaticQuery,graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick";
const Gallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <StaticQuery
      query={graphql`
        query allImgQuery {
          source: allFile(filter: { absolutePath: { regex: "/gallery/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxHeight: 500) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const images = data.source.edges
       
        return (
          <>
            
          <Slider {...settings}>
          {images.map(({node})=>(
              <Img fluid={node.childImageSharp.fluid}    className="gallery-picture"/>
            ))}
          </Slider>
         
          
           </>

        )
      }}
    />
  )


}
export default Gallery;
